import React, { useState, useEffect } from "react";
import { BasicFormWrapper } from "../../../../containers/styled";
import Heading from "../../../heading/heading";
import { Cards } from "../../../cards/frame/cards-frame";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import { formatter } from "../../../../helpers/helper";
import {
  calculateSquareFootCost,
  calculateRemoveSealingCost,
} from "../../../helpers/helper";
import { Form, Input } from "antd";

function QuoteReview(props) {
  const { form, setStep, roofObjects } = props;

  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setFormValues({ ...formValues, ...form.getFieldsValue(true) });
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(true),
      comments: formValues.comments,
      discount: formValues.discount,
    });

    // eslint-disable-next-line
  }, [formValues.comments, formValues.discount]);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const generateDiscount = () => {
    if (formValues.discount && parseFloat(formValues.discount) > 0) {
      return parseFloat(formValues.discount);
    }

    return 0;
  };

  const generateRoofObjects = () => {
    if (formValues.roof_objects && formValues.roof_objects.length > 0) {
      return formValues.roof_objects.map((ro) => {
        let roofObject = roofObjects.find(
          (roofObject) => parseInt(roofObject.id, 10) === parseInt(ro.id, 10)
        );
        return (
          <p id={ro.id}>
            {roofObject.name} - {ro.quantity} -{" "}
            {formatter.format((roofObject.cost / 100) * ro.quantity)}
          </p>
        );
      });
    }
  };

  const calculateRoofQuoteCost = (quote) => {
    let totalCost = 0;

    // Add square foot cost
    totalCost += parseFloat(calculateSquareFootCost(quote["square_foot"], 1));

    // Add parapet cost
    if (quote.has_parapet) {
      totalCost += quote.parapet_foot * 3;
    }

    // Add puddle cost
    if (quote.has_puddle) {
      totalCost += quote.puddle_square_foot * 3;
    }

    // Add roof sealing removal cost
    if (quote.remove_roof_sealing) {
      totalCost += parseFloat(
        calculateRemoveSealingCost(quote["square_foot"], 1)
      );
    }

    // Add roof objects removal cost
    if (quote.roof_objects && quote.roof_objects.length > 0) {
      totalCost += quote.roof_objects.reduce((accumulator, item) => {
        let roofObject = roofObjects.find(
          (roofObject) => parseInt(roofObject.id, 10) === parseInt(item.id, 10)
        );

        return (
          accumulator + parseFloat(item.quantity) * (roofObject.cost / 100)
        );
      }, 0);
    }

    return totalCost;
  };

  // const generateTotal = () => {
  //   let cost = generateFinalPrice() - generateDiscount();
  //   cost = cost > 0 ? cost : 0;

  //   return formatter.format(cost);
  // };

  return (
    <BasicFormWrapper style={{ width: "100%" }}>
      <div className="atbd-review-order" style={{ width: "100%" }}>
        <Heading as="h4">4. Revisar y Confirmar Cotización</Heading>
        <Cards
          bodyStyle={{ backgroundColor: "#F8F9FB", borderRadius: 10 }}
          headless
        >
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Información del Cliente
                  <Link to="#" onClick={() => setStep(0)}>
                    <FeatherIcon icon="edit" />
                    Editar
                  </Link>
                </Heading>
              </div>
              <article className="atbd-review-order__shippingInfo">
                <p>Nombre del cliente: {formValues["customer_name"]}</p>
                <p>Dirección: {formValues["address_line1"]}</p>
                <p>Municipio: {formValues["municipality"]}</p>
                <p>País: {formValues["state"]}</p>
              </article>
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Detalles de Proyecto
                  <Link to="#" onClick={() => setStep(1)}>
                    <FeatherIcon icon="edit" />
                    Editar
                  </Link>
                </Heading>
              </div>
              {/* <p>
                  Has Additional Elements:{" "}
                  {formValues["has_additional_elements"] ? "true" : "false"}
                </p> */}
              <p>Pies cuadrados: {formValues["square_foot"]}</p>
              {/* <p>Membrana: {formValues["membrane"]}</p> */}
              <p>
                Costo estimado:{" "}
                {formatter.format(
                  calculateSquareFootCost(formValues["square_foot"], 1)
                )}
              </p>
              <hr></hr>
              <p>Tiene pretil: {formValues["has_parapet"] ? "si" : "no"}</p>
              {formValues["has_parapet"] && (
                <>
                  <p>Pies: {formValues["parapet_foot"]}</p>
                  <p>
                    Costo estimado:{" "}
                    {formatter.format(formValues["parapet_foot"] * 3)}
                  </p>
                  <hr></hr>
                </>
              )}
              <p>
                Tiene empozamiento: {formValues["has_puddle"] ? "si" : "no"}
              </p>
              {formValues["has_puddle"] && (
                <>
                  <p>Pies cuadrados: {formValues["puddle_square_foot"]}</p>
                  <p>
                    Costo estimado:{" "}
                    {formatter.format(formValues["puddle_square_foot"] * 3)}
                  </p>
                </>
              )}
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless>
              <div className="atbd-review-order__shippingTitle">
                <Heading as="h5">
                  Remoción o Corrección de Objetos
                  <Link to="#" onClick={() => setStep(2)}>
                    <FeatherIcon icon="edit" />
                    Editar
                  </Link>
                </Heading>
                <p>
                  Remover sellado existente:{" "}
                  {formValues["remove_roof_sealing"] ? "si" : "no"}
                </p>
                {formValues["remove_roof_sealing"] && (
                  <>
                    <p>
                      Costo estimado:{" "}
                      {formatter.format(
                        calculateRemoveSealingCost(
                          formValues["square_foot"],
                          1
                        )
                      )}
                    </p>
                  </>
                )}

                {formValues["remove_roof_sealing"] &&
                  formValues["remove_roof_object"] && <hr></hr>}
                <p>
                  Remover o corregir objetos existentes:{" "}
                  {formValues["remove_roof_object"] ? "si" : "no"}
                </p>
                {formValues["remove_roof_object"] && (
                  <>
                    <p>Objetos a remover o corregir:</p>
                    {formValues["remove_roof_object"] &&
                      generateRoofObjects()}
                  </>
                )}
              </div>
            </Cards>
          </div>
          <div className="atbd-review-order__single">
            <Cards headless bodypadding={"10px"}>
              <div>
                <Heading as="h5">Discount</Heading>
              </div>
              <div className="promo-apply-form">
                <Form.Item name="discount" label="" onChange={handleChange}>
                  <Input placeholder="500" />
                </Form.Item>
              </div>
              <br></br>
            </Cards>
          </div>

          <div className="atbd-review-order__single">
            <Cards headless>
              <div>
                <Heading as="h5">Resumen de Cotización </Heading>
              </div>
              <p>
                Sub-Total:{" "}
                <strong>
                  {formatter.format(calculateRoofQuoteCost(formValues))}
                </strong>
              </p>
              <p>
                Tax:{" "}
                <strong>
                  {formatter.format(
                    calculateRoofQuoteCost(formValues) *
                    0.115
                  )}
                </strong>
              </p>
              {formValues.discount && parseFloat(formValues.discount) > 0 && (
                <p className="text-danger">
                  Discount:{" "}
                  <strong>-{formatter.format(generateDiscount())}</strong>
                </p>
              )}
              <h2>
                Total:{" "}
                <strong>
                  {formatter.format(
                    (calculateRoofQuoteCost(formValues) *
                      1.115) -
                    generateDiscount()
                  )}
                </strong>
              </h2>

              <p>
                Costo por pie cuadrado:{" "}
                <strong>
                  {formatter.format(
                    ((calculateRoofQuoteCost(formValues) *
                      1.115) - generateDiscount()) /
                    formValues.square_foot
                  )}
                </strong>
              </p>
            </Cards>
          </div>

          <div className="atbd-review-order__single">
            <Cards headless bodypadding={"10px"}>
              <div>
                <Heading as="h5">Commentarios</Heading>
              </div>
              <div className="promo-apply-form">
                <Form.Item
                  name="comments"
                  label=""
                  onChange={handleChange}
                  value={formValues.comments ? formValues.comments : ""}
                >
                  <Input.TextArea
                    placeholder="Escribe aquí tus comentarios"
                    rows={3}
                  />
                </Form.Item>
              </div>
            </Cards>
          </div>
        </Cards>
      </div>
    </BasicFormWrapper>
  );
}

let mapStateToProps = (state) => ({
  roofObjects: state.roofObjects.roofObjects,
  products: state.products.products,
});

export default connect(mapStateToProps)(QuoteReview);
